import { useState } from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import Image from "gatsby-image"

import Layout from "src/layout"
import { Column, PageHead, Section, BgImage } from "src/layout/styled"
import { COLORS, FONTS, LAYOUT } from "src/layout/constants"
import GearsIcon from "src/svg/gears.svg"
import RightIcon from "src/svg/right.svg"

const Container = styled.div`
  margin: ${LAYOUT.margin * 3}px 0;
  @media (max-width: 799px) {
    margin: ${LAYOUT.margin * 2}px 0 ${LAYOUT.margin}px;
  }
`
const CollapsedSection = styled(Section)`
  padding: 0 ${LAYOUT.margin}px;
  @media (max-width: 1099px) {
    ${BgImage} {
      padding-left: 40px;
    }
  }
  @media (max-width: 799px) {
    margin-bottom: 10px;
  }
`
const Subtitle = styled.h3<{ $open: boolean }>`
  font-family: ${FONTS.title};
  color: ${COLORS.white};
  font-size: 2.75em;
  line-height: 1.3em;
  position: relative;
  svg {
    position: absolute;
    left: -60px;
    top: 12px;
    transition: transform 0.5s ease-in-out;
    transform: rotate(${({ $open }) => ($open ? "90deg" : "0deg")});
  }
  span {
    box-shadow: ${COLORS.translucentWhite} 10px 0px 0px 0px, ${COLORS.translucentWhite} -10px 0px 0px 0px;
    background-color: ${COLORS.translucentWhite};
  }
  @media (max-width: 799px) {
    font-size: 2em;
    svg {
      width: 24px;
      height: 24px;
      top: 3px;
      left: -45px;
    }
  }
`
const Collapsible = styled.div`
  transition: height 0.5s ease-in-out;
  overflow: hidden;
`
const Content = styled(Column)`
  @media (max-width: 799px) {
    margin: 30px 0;
  }
`

interface Props {
  image: { childImageSharp: Media }
  title: string
}

const ExpandableSection: React.FC<Props> = ({ image, title, children }) => {
  const [open, setOpen] = useState(false)
  const [height, setHeight] = useState<number>()

  return (
    <>
      <CollapsedSection>
        <BgImage>
          <button onClick={() => setOpen(!open)}>
            <Image fluid={image.childImageSharp.fluid} />
            <Column>
              <Subtitle $open={open}>
                <RightIcon />
                <span>{title}</span>
              </Subtitle>
            </Column>
          </button>
        </BgImage>
      </CollapsedSection>
      <Collapsible
        ref={(node) => {
          if (node && !height) {
            // TODO: handle resizing of window
            setHeight(node.getBoundingClientRect().height)
          }
        }}
        style={height ? { height: open ? height : 0 } : null}
      >
        <Content>{children}</Content>
      </Collapsible>
    </>
  )
}

interface Data {
  study: { childImageSharp: Media }
  animation: { childImageSharp: Media }
  partner: { childImageSharp: Media }
}

const MethodsPage: GatsbyPage<Data> = ({ data }) => {
  const subtitle = "L’ensemble de nos missions est nourri par la recherche en sciences sociales."

  return (
    <Layout theme="blue" title="Méthodes" description={subtitle}>
      <PageHead>
        <GearsIcon />
        <h1>expertise et méthodes</h1>
        <h2>{subtitle}</h2>
      </PageHead>
      <Container>
        <ExpandableSection image={data.study} title="Nos méthodes d’études et de recherche">
          <h4>Enquêter : une exigence d’immersion</h4>
          <p>
            Nous privilégions une forte présence sur le terrain, au plus près de la diversité des situations et des
            réalités vécues par les usager·ères, professionnel·les, et décideur·ses.
          </p>
          <h4>Des méthodes qualitatives créatives et rigoureuses</h4>
          <p>
            Nous sommes expert·es des entretiens semi-directifs individuels, des récits de vie, des entretiens
            collectifs, des focus groups et des observations in situ. Pour prendre en compte la parole des personnes
            inaudibles, nous mobilisons des outils projectifs et des méthodes d’animation favorisant l’expression de
            toutes et tous.
          </p>
          <h4>Des méthodes quantitatives sur-mesure</h4>
          <p>
            Nous menons des enquêtes par questionnaires et procédons à des analyses statistiques bivariées et
            multivariées complexes et rigoureuses. Les questionnaires peuvent être entièrement construits par nos
            équipes, ou faire l’objet d’un travail de co-construction avec nos partenaires. Ils sont adaptés à la
            diversité des publics enquêtés.
          </p>
          <h4>Cartographie et analyse de réseaux</h4>
          <p>
            Afin de mieux comprendre la dynamique et le fonctionnement des réseaux, nous produisons des cartographies
            sociales permettant de repérer les dynamiques de coopération. Ces cartographies sont particulièrement utiles
            pour représenter et analyser des partenariats et comme outil favorisant le débat entre les participants
            d’une même communauté ou d’un même écosystème.
          </p>
          <h4>Analyse documentaire</h4>
          <p>
            Dans l’ensemble de nos missions, nous mobilisons les avancées et les enseignements des sciences sociales et
            politiques afin de contextualiser les problématiques, de prendre en compte la diversité des situations des
            personnes enquêtées et de produire des outils d’enquête pertinents.
          </p>
        </ExpandableSection>
        <ExpandableSection image={data.animation} title="Nos méthodes d’animations et de co-construction">
          <h4>Ateliers de co-construction d’impacts et d’indicateurs</h4>
          <p>
            En amont de réflexions stratégiques ou d’études d’impact social, nous organisons des ateliers de
            co-construction d’une vision du changement social souhaité ou à démontrer. Nous proposons alors des
            questionnements concrets pour identifier les impacts attendus et les indicateurs adaptés aux
            caractéristiques des différentes parties prenantes d’un projet.
          </p>
          <h4>Ateliers d’intelligence collective</h4>
          <p>
            Nous proposons des ateliers d’intelligence collective pour permettre aux parties prenantes de définir
            ensemble des problématiques communes et des solutions à expérimenter collectivement. Nos méthodes
            d’animation renforcent l’écoute, une distribution équilibrée de la parole et la construction d’une relation
            de confiance.
          </p>
          <h4>Programmes d’exploration et de concertation</h4>
          <p>
            Alors que la construction des politiques publiques est souvent confrontée à la difficulté de faire
            participer durablement des collectifs de citoyen·nes, nous proposons des programmes qui permettent de
            réinventer les logiques de concertation démocratique. Nous formons et accompagnons des collectifs dans la
            compréhension des enjeux et la production de préconisations.
          </p>
          <h4>Instances de gouvernance stratégique</h4>
          <p>
            Nous organisons et animons des comités de pilotage et des instances stratégiques et accompagnons les acteurs
            dans la mise en débat des solutions et des scénarios. Nous apportons du contenu original et de qualité afin
            de faciliter les processus de décision.
          </p>
        </ExpandableSection>
        <ExpandableSection
          image={data.partner}
          title="Des partenariats scientifiques au service d’une expertise thématique fine"
        >
          <p>
            Nous travaillons en partenariat avec des groupes d’experts chercheurs que nous constituons en comité
            scientifique afin d’alimenter les réflexions et d’être en prise avec les dernières avancées scientifiques.
            Dans ce cadre, nous collaborons avec des chercheur·es de diverses institutions (Sciences Po Paris, CNRS,
            INSERM, OFDT, etc.)
          </p>
          <p>
            Au quotidien, nous échangeons avec des chercheur·res pour enrichir nos analyses et nos méthodes. Ces
            collaborations sont facilitées par la présence d’un nombre importants de Docteurs au sein de notre équipe.
          </p>
        </ExpandableSection>
      </Container>
    </Layout>
  )
}

export default MethodsPage

export const query = graphql`
  query {
    study: file(relativePath: { eq: "method-study.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1200, quality: 75) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    animation: file(relativePath: { eq: "method-animation.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1200, quality: 75) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    partner: file(relativePath: { eq: "method-partner.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1200, quality: 75) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
